<template>
    <div class="second-bar">
        <div class="second-bar-inside-box">
            <router-link
                v-for="item of items"
                :class="['menu-item', computedClass(item)]"
                :key="item.text"
                :to="{ path: item.path }"
                v-permission="['premium']">
                {{ item.text }}
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'HomeSecondBar',
    computed: {
        ...mapGetters(['user']),
        active() {
            return this.$route.query.tab || 'dashboard';
        },
    },
    data() {
        return {
            items: [
                { path: '/dashboard', text: 'Dashboard' },
                { path: '/assets', text: 'Assets' },
                { path: '/map', text: 'Map' },
                { path: '/connect', text: 'Connect' },
                { path: '/ai', text: 'A.I.' },
                { path: '/shadow', text: 'Shadow' },
                { path: '/flow', text: 'Flow' },
                { path: '/ruleflow', text: 'RuleFlow' },
                { path: '/user', text: 'User' }
            ]
        };
    },
    methods: {
        isActive(path) {
            if (this.$route.path == '/' && path === '/dashboard') {
                return true;
            }
            return this.$route.path.startsWith(path);
        },
        computedClass(item) {
            return {
                'selected': this.isActive(item.path),
            }
        }
    },
    mounted() {
        if (this.user && this.user.plan === 'premium') {
            this.items.push();
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* *********************************************************  second-bar  **************************************************************************** */

.second-bar {
    width: 100%;
    /* height: 56px; */
    background: #3C4556;
}

.second-bar-inside-box {
    z-index: 0;
    margin-left: 120px;
    margin-right: 120px;
    /* overflow-x: auto; */
    /* overflow-y: hidden; */
    box-sizing: border-box;
    /* width: 100vw; */
    display: flex;
    flex-wrap: wrap;
    height: 56px;
}

/*    .menu {
        height: 56px;
        color: #FCB900 !important;
    }*/

a {
    /* 不压缩 */
    flex-shrink: 0;
    text-decoration: none;
}

.menu-item {
    display: inline-block;
    z-index: 1;
    text-align: center;

    width: 130px;
    height: 56px;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    /*color: #FCB900;*/
    color: #FFFFFF;
    line-height: 56px;
    /*position: relative;*/
}

.selected {
    border-bottom: 2px solid #FCB900;
    color: #FCB900;
    box-sizing: border-box;
}

.assets {
    display: inline-block;

    z-index: 1;
    margin-left: 68px;

    width: 47px;
    height: 22px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 22px;
}

.connect {
    display: inline-block;

    z-index: 1;
    margin-left: 77px;

    width: 59px;
    height: 22px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 22px;
}

.flow {
    display: inline-block;

    z-index: 1;
    margin-left: 84px;

    width: 33px;
    height: 22px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 22px;
}
</style>