import VueRouter from "vue-router";
import { EventBus } from "../event-bus.js";
import { setCookie } from "../utils/cookie.js";
import store from "@/store";
import Layout from "@/components/Layout.vue";

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/Login.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("@/pages/Signup.vue"),
  },
  {
    path: "/requestResetPassword",
    name: "requestResetPassword",
    component: () => import("@/pages/RequestResetPassword.vue"),
  },
  {
    path: "/requestResetPassword",
    name: "resetPassword",
    component: () => import("@/pages/ResetPassword.vue"),
  },
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/components/Dashboard.vue"),
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/components/Dashboard.vue"),
      },
      {
        path: "/assets",
        name: "assets",
        component: () => import("@/components/Assets.vue"),
      },
      {
        path: "/map",
        name: "map",
        component: () => import("@/components/Map.vue"),
      },
      {
        path: "/connect",
        name: "connect",
        component: () => import("@/components/Connect.vue"),
      },
      {
        path: "/ai",
        name: "ai",
        component: () => import("@/components/AI.vue"),
      },
      {
        path: "/shadow",
        name: "shadow",
        component: () => import("@/components/Shadow.vue"),
      },
      {
        path: "/flow",
        name: "flow",
        component: () => import("@/components/Flow.vue"),
      },
      {
        path: "/ruleflow",
        name: "ruleflow-list",
        component: () => import("@/components/rulego/RuleGoList.vue"),
      },
      {
        path: "/ruleflow-new",
        name: "ruleflow-new",
        component: () => import("@/components/rulego/RuleGoEditor.vue"),
      },
      {
        path: "/ruleflow/:id",
        name: "ruleflow-edit",
        component: () => import("@/components/rulego/RuleGoEditor.vue"),
        props: true,
      },
      {
        path: "/setting",
        name: "setting",
        component: () => import("@/pages/Setting.vue"),
      },
      {
        path: "/user",
        name: "user",
        component: () => import("@/components/user/UserListPage.vue"),
      },
    ],
  },
  {
    //404
    path: "*",
    name: "notfound",
    component: () => import("@/pages/NotFound.vue"),
  }
];

// 3. 创建 router 实例，然后传 `routes` 配置
// 你还可以传别的配置参数, 不过先这么简单着吧。
const router = new VueRouter({
  mode: "hash",
  routes: routes, // (缩写) 相当于 routes: routes
});

const whiteList = ["login", "signup", "requestResetPassword", "resetPassword"];
router.beforeEach(async (to, from, next) => {
  const isAuthenticated = localStorage.getItem("token");

  // 如果localStorage中有token，就说明已经登录。获取全局状态中用户信息
  if (
    isAuthenticated &&
    store.state.user === null &&
    store.state.loadingUser === false
  ) {
    await store.dispatch("getUserInfo");
  }

  if (isAuthenticated) {
    next();
  } else if (whiteList.indexOf(to.name) > -1) {
    next();
  } else {
    next({
      name: "login",
    });
  }
});

EventBus.$on("logout", () => {
  // 进入登录页面
  setCookie("access-token", "/", ".cereb.ai");
  localStorage.removeItem("token");
  if (router.currentRoute.name !== "login") {
    router.go({
      name: "login",
    });
  }
});

export default router;
