//use case <div v-permission="['admin','editor']"></div>
import store from "@/store";

export default {
  inserted(el, binding) {
    const { value } = binding;
    const roles = [store.state.user.plan];
    if (value && value instanceof Array && value.length > 0) {
      const permissionRoles = value;
      const hasPermission = roles.some((role) => {
        return permissionRoles.includes(role);
      });

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error(`need roles! Like v-permission="['normal','premium','organization-manager','staff']"`);
    }
  },
};
